import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Text } from './Text'
import { useTemplate } from '../../../utils/custom-hooks/use-getters/useTemplate'

const StyledPageTitle = styled.h3`
  padding: 15px 0;
  ${(p) => {
    let dynamic = ''
    if (p.fontFamily) {
      dynamic += `font-family: ${p.fontFamily};\n`
    }
    if (p.fontSize) {
      dynamic += `font-size: ${p.fontSize};\n`
    }
    return dynamic
  }};
  
  @media (max-width: 992px) {
    padding: 0 0 15px 0;
  }
`

export const PageTitle = ({ children }) => {
  const { props: pageTitleTemplate } = useTemplate('PageTitle')

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledPageTitle {...pageTitleTemplate}>
      <Text isBold fontSize={pageTitleTemplate.fontSize ?? '1.6rem'}>
        {children}
      </Text>
    </StyledPageTitle>
  )
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
