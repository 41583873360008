import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { AsyncInput, CheckboxInput, Icon } from 'jmt-styled-components'

import { FlexBox } from '../../../components/base/layouts/FlexBox'
import { Card } from '../../../components/base/layouts/Card'
import { SectionTitle } from '../../../components/base/texts/SectionTitle'
import { LinkBox } from '../../../components/dynamic-components/LinkBox'
import { Text } from '../../../components/base/texts/Text'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'
import { RowItem } from './RowItem'
import { MasterAnchor } from '../../../components/MasterAnchor'
import { useFlags } from '../../../utils/custom-hooks/use-getters/useFlags'
import { setMembersDirectoryVisibilityAction } from '../../../state/app-info/app-info-actions'
import { showProfileModalAction } from '../../../components/profile-modal/state/profile-modal-actions'
import { AccessPermissionChecker } from '../../../components/permissions/AccessPermissionChecker'

const LinkWrapper = styled.div`
  margin: 15px 0;
`

const CardBody = styled.div`
  padding: 20px 25px;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > h3 {
    color: ${(p) => p.theme.primaryColor};
    margin-bottom: 1rem;
  }
  & > p {
    padding-bottom: 1rem;
  }
  & > a {
    margin-top: auto;
  }
`

const IconSpacer = styled.span`
  padding-left: 0.5rem;
`

const ActionText = styled.span`
  cursor: pointer;
`

const constructRoleText = (role) => `${role.Name}${role.Date && role.DateTo ? ` (${dayjs(role.Date).format('DD/MM/YYYY')} - ${dayjs(role.DateTo).format('DD/MM/YYYY')})` : ''}`

export const MemberView = ({
  membership,
}) => {
  const {
    Number,
    TypeShort,
    MemberPositionTags: ActiveRoles,
    Status,
    ListItems,
    CertificateDownloadUrl,
    ReceiptDownloadUrl,
    AccountBalance,
    AccountCurrency,
    ApplicationClosed,
    StatusBox,
    Newsletters,
    PayNowLink,
    ExpiryDate,
    Blocked,
    ShowInDirectory,
  } = membership

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    showNewsletterRegistration,
    showMembershipStatus,
    showMembershipAssociationProfileEditForm,
    showMembershipActiveRole,
  } = useFlags()

  const ConvertedStatus = (() => {
    if (Blocked) {
      return 'Blocked'
    }
    if (
      (['none', 'applicant'].includes(Status) && AccountBalance < 0)
      // || EnableMembershipRenew
    ) {
      if (ApplicationClosed) {
        return 'Application_Closed'
      }
      return 'To_Pay'
    }
    if (Status === 'none') {
      if (ApplicationClosed) {
        return 'Application_Closed'
      }
      return 'Expired'
    }
    if (Status) {
      return `${Status[0].toUpperCase()}${Status.slice(1)}`
    }

    if (ApplicationClosed) {
      return 'Application_Closed'
    }

    return 'Not_Member'
  })()

  if (ConvertedStatus === 'Blocked') {
    return (
      <LinkWrapper>
        <Card>
          <CardBody>
            <h3>
              <Text isPrimary fontSize="1.2rem" isBold>
                {t(`Membership_Page-Status_Box-${ConvertedStatus}-Title`)}
              </Text>
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t(`Membership_Page-Status_Box-${ConvertedStatus}-Description`),
              }}
            />
          </CardBody>
        </Card>
      </LinkWrapper>
    )
  }

  if (ConvertedStatus === 'Application_Closed') {
    return (
      <LinkWrapper>
        <Card>
          <CardBody>
            <h3>
              <Text isPrimary fontSize="1.2rem" isBold>
                {t(`Membership_Page-Status_Box-${ConvertedStatus}-Title`)}
              </Text>
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t(`Membership_Page-Status_Box-${ConvertedStatus}-Description`),
              }}
            />
          </CardBody>
        </Card>
      </LinkWrapper>
    )
  }

  if (ConvertedStatus === 'Not_Member') {
    return (
      <LinkWrapper>
        <LinkBox
          data={{
            title: t(`Membership_Page-Status_Box-${ConvertedStatus}-Title`),
            description: t(`Membership_Page-Status_Box-${ConvertedStatus}-Description`),
            buttonTitle: t(`Membership_Page-Status_Box-${ConvertedStatus}-Button`),
            buttonIcon: StatusBox.ButtonIcon,
            link: {
              url: StatusBox.ButtonLink.Url,
              target: StatusBox.ButtonLink.Target,
            },
          }}
        />
      </LinkWrapper>
    )
  }

  let expiryDateString
  let daysNum
  if (ExpiryDate) {
    expiryDateString = dayjs(ExpiryDate).format('DD/MM/YYYY')
    const date1 = dayjs(ExpiryDate)
    daysNum = date1.diff(dayjs(), 'day')
  }

  return (
    <>
      <LinkWrapper>
        <LinkBox
          data={{
            title: t(
              `Membership_Page-Status_Box-${ConvertedStatus}-Title`,
              { expiryDate: expiryDateString, daysNumber: daysNum },
            ),
            description: t(`Membership_Page-Status_Box-${ConvertedStatus}-Description`),
            buttonTitle: t(`Membership_Page-Status_Box-${ConvertedStatus}-Button`),
            buttonIcon: StatusBox.ButtonIcon,
            link: {
              ...(ConvertedStatus === 'To_Pay' ? {
                url: PayNowLink,
                target: '_blank',
              } : {
                url: StatusBox.ButtonLink.Url,
                target: StatusBox.ButtonLink.Target,
              }),
            },
          }}
        />
      </LinkWrapper>
      <Card
        wrapperAdditionalStyles={`
          margin: 5px 0;
        `}
      >
        <CardBody>
          <FlexBox
            dir="column"
          >
            <RowItem
              label={t('Membership_Page-Membership_Number')}
              value={Number}
            />
            <RowItem
              label={t('Membership_Page-Membership_Type')}
              value={TypeShort}
            />
            {
              showMembershipActiveRole === true && (
                <RowItem
                  label={t('Membership_Page-Active_Roles')}
                  value={ActiveRoles.reduce((acc, role, idx) => (
                    `${acc}${constructRoleText(role)}${ActiveRoles.length > idx + 1 ? ', ' : ''}`
                  ), '')}
                />
              )
            }
            {
              (showMembershipStatus === false) || (
                <RowItem
                  label={t('Membership_Page-Membership_Status')}
                  value={t(`Membership_Page-Membership_Status-${ConvertedStatus}`)}
                />
              )
            }
            {
                AccountBalance < 0
                && (
                  <RowItem
                    label={(
                      <>
                        {t('Membership_Page-Payment-Status')}
                      </>
                  )}
                    value={(
                      <>
                        {t('Membership_Page-Payment-Amount_due')}
                        :
                        {' '}
                        {`${AccountCurrency} ${AccountBalance}`}
                        {' '}
                        <a href={PayNowLink} target="_blank"> {/* eslint-disable-line */}
                          <Text isBold isPrimary>
                            {t('Membership_Page-Payment-Pay_Now')}
                          </Text>
                          <IconSpacer>
                            <Icon icon="fa-external-link-alt" />
                          </IconSpacer>
                        </a>
                      </>
                  )}
                  />
                )
              }
            <RowItem
              label={t('Membership_Page-Certificate_Download')}
              value={(
                <a
                  href={CertificateDownloadUrl}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text isBold isPrimary>
                    {t('Membership_Page-Certificate_Download-Button')}
                  </Text>
                  <IconSpacer>
                    <Icon icon="fa-download" />
                  </IconSpacer>
                </a>
              )}
            />
            {
              ReceiptDownloadUrl && (
                <RowItem
                  label={t('Membership_Page-Receipt_Download')}
                  value={(
                    <a
                      href={ReceiptDownloadUrl}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text isBold isPrimary>
                        {t('Membership_Page-Receipt_Download-Button')}
                      </Text>
                      <IconSpacer>
                        <Icon icon="fa-download" />
                      </IconSpacer>
                    </a>
                  )}
                />
              )
            }
            <RowItem
              label={t('Membership_Page-Visible_In_Members_Directory')}
              value={(
                <AsyncInput>
                  {
                    ({ loaderInit, loaderEnd, isLoading }) => (
                      <CheckboxInput
                        name="members-directory-visibility"
                        checked={ShowInDirectory}
                        disabled={isLoading}
                        onChange={() => {
                          loaderInit()
                          dispatch(setMembersDirectoryVisibilityAction({ nextValue: !ShowInDirectory }))
                            .then(() => loaderEnd())
                        }}
                      />
                    )
                  }
                </AsyncInput>
              )}
            />
          </FlexBox>
        </CardBody>
      </Card>

      {
        showMembershipAssociationProfileEditForm === true && (
          <Card
            wrapperAdditionalStyles={`
              margin: 5px 0;
            `}
          >
            <CardBody>
              <RowItem
                label={t('Membership_Page-Profile-Title')}
                value={(
                  <ActionText
                    onClick={() => {
                      dispatch(showProfileModalAction({ addressType: 'MembershipJournal' }))
                    }}
                  >
                    <Text isBold isPrimary>
                      {t('Membership_Page-Profile-Description')}
                    </Text>
                  </ActionText>
                )}
              />
            </CardBody>
          </Card>
        )
      }

      {
        showNewsletterRegistration && Newsletters && Newsletters.map((newsletter) => (
          <Card
            key={newsletter.Name}
            wrapperAdditionalStyles={`
              margin: 5px 0;
            `}
          >
            <CardBody>
              <RowItem
                label={t(`Membership_Page-Newsletter-${newsletter.Name}`)}
                value={
                  newsletter.Subscribed
                    ? t('Membership_Page-Newsletter-Subscribed')
                    : t('Membership_Page-Newsletter-Not_Subscribed')
                }
              />
            </CardBody>
          </Card>
        ))
      }

      {
        ListItems.length
          ? (
            <>
              <SectionTitle padding="30px 0 15px 0">
                <Text isBold>
                  {t('Membership_Page-Benefits-Title')}
                </Text>
              </SectionTitle>
              {
                ListItems.map((item) => {
                  if (
                    item.ButtonLink.Url === '/membership/members-directory'
                  ) {
                    return (
                      <AccessPermissionChecker
                        key={item.Title}
                        accessRequirements={{
                          membership: {
                            showInDirectory: true,
                          },
                        }}
                      >
                        {(isPermitted) => {
                          if (isPermitted === 'permitted') {
                            return (
                              <Card
                                key={item.Title}
                                wrapperAdditionalStyles={`
                                  margin: 5px 0;
                                `}
                              >
                                <CardBody>
                                  <RowItem
                                    label={item.Title}
                                    value={(
                                      <MasterAnchor to={item.ButtonLink.Url} target={item.ButtonLink.Target}>
                                        {({ isRelative }) => (
                                          <>
                                            <Text isBold isPrimary>
                                              {item.Description}
                                            </Text>
                                            {
                                              isRelative || (
                                                <IconSpacer>
                                                  <Icon icon="fa-external-link-alt" />
                                                </IconSpacer>
                                              )
                                            }
                                          </>
                                        )}
                                      </MasterAnchor>
                                    )}
                                  />
                                </CardBody>
                              </Card>
                            )
                          }
                          return null
                        }}
                      </AccessPermissionChecker>
                    )
                  }
                  return (
                    <Card
                      key={item.Title}
                      wrapperAdditionalStyles={`
                        margin: 5px 0;
                      `}
                    >
                      <CardBody>
                        <RowItem
                          label={item.Title}
                          value={(
                            <MasterAnchor to={item.ButtonLink.Url} target={item.ButtonLink.Target}>
                              {({ isRelative }) => (
                                <>
                                  <Text isBold isPrimary>
                                    {item.Description}
                                  </Text>
                                  {
                                    isRelative || (
                                      <IconSpacer>
                                        <Icon icon="fa-external-link-alt" />
                                      </IconSpacer>
                                    )
                                  }
                                </>
                              )}
                            </MasterAnchor>
                      )}
                        />
                      </CardBody>
                    </Card>
                  )
                })
            }
            </>
          )
          : null
      }
    </>
  )
}

MemberView.propTypes = {
  membership: PropTypes.shape({
    ListItems: PropTypes.arrayOf(
      PropTypes.shape({
        Title: PropTypes.string,
        ButtonTitle: PropTypes.string,
        ButtonIcon: PropTypes.string,
        ButtonLink: PropTypes.shape({
          Url: PropTypes.string,
          Target: PropTypes.string,
        }),
      }),
    ).isRequired,
    StatusBox: PropTypes.shape({
      Title: PropTypes.string,
      ButtonTitle: PropTypes.string,
      ButtonIcon: PropTypes.string,
      ButtonLink: PropTypes.shape({
        Url: PropTypes.string,
        Target: PropTypes.string,
      }),
    }).isRequired,
    Number: PropTypes.string.isRequired,
    TypeShort: PropTypes.string.isRequired,
    Status: PropTypes.string.isRequired,
    AccountCurrency: PropTypes.string.isRequired,
    AccountBalance: PropTypes.number.isRequired,
    ApplicationClosed: PropTypes.bool,
    MemberPositionTags: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string.isRequired,
      }),
    ),
    CertificateDownloadUrl: PropTypes.string.isRequired,
    ReceiptDownloadUrl: PropTypes.string,
    ExpiryDate: PropTypes.string,
    PayNowLink: PropTypes.string,
    Blocked: PropTypes.bool,
    Newsletters: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string.isRequired,
        Subscribed: PropTypes.bool.isRequired,
      }),
    ),
    ShowInDirectory: PropTypes.bool.isRequired,
  }).isRequired,
}
