import PropTypes from 'prop-types'

import { PageTitle } from '../../../components/base/texts/PageTitle'
import { PageContainer } from '../../../components/base/layouts/PageContainer'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'
import { Brevo } from '../../../components/Brevo'

export const BrevoNewsletter = ({ title }) => {
  const { t } = useTranslation()
  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      {
        process.env.REACT_APP_ENV !== 'production' && (
          <p
            dangerouslySetInnerHTML={{
              __html: t('Global-Production_Content-Warning-Above'),
            }}
          />
        )
      }
      <Brevo />
    </PageContainer>
  )
}

BrevoNewsletter.propTypes = {
  title: PropTypes.string.isRequired,
}
