import { PageContainer } from 'jmt-styled-components'
import { PageTitle } from '../components/base/texts/PageTitle'

export const NotFound = () => (
  <PageContainer>
    <PageTitle>
      Page Not Found
      {/* TODO: replace with text-resource */}
    </PageTitle>
  </PageContainer>
)
