import PropTypes from 'prop-types'
import { ButtonLink as JmtButtonLink } from 'jmt-styled-components/dist/buttons/ButtonLink'

import { useTemplate } from '../../../utils/custom-hooks/use-getters/useTemplate'

export const ButtonLink = ({ ...props }) => {
  const template = useTemplate('Button')
  return (
    <JmtButtonLink
          // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      template={props.template || template.props}
    />
  )
}

ButtonLink.propTypes = {
  showLoader: PropTypes.bool,
  template: PropTypes.shape(),
  isExternal: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  link: PropTypes.shape({
    target: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
}

ButtonLink.defaultProps = {
  showLoader: false,
  template: null,
  children: '',
  isExternal: false,
}
