import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import PropTypes from 'prop-types'
import { fetchComponentTemplatedResources } from 'jmt-fetch-component-resources'
import { PageTitle } from '../../../components/base/texts/PageTitle'

import { PageContainer } from '../../../components/base/layouts/PageContainer'
import { fetchSummeryInfo } from '../../../state/app-info/app-info-actions'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'
import { useShallowEqualSelector } from '../../../utils/custom-hooks/useShallowEqualSelector'
import { ButtonLink } from '../../../components/base/buttons/ButtonLink'
import { SubRoutesButtons } from '../../../components/SubRoutesButtons'

const LinkContainer = styled.div`
  display: flex;
  column-gap: 15px;
  button {
    padding: 5px 10px;
  }
`

export const MyProfileRoot = ({ title, subRoutes }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const catchUpdate = (e) => {
      const update = e.detail
      if (update === 'success') {
        dispatch(fetchSummeryInfo(true))
      }
    }
    window.addEventListener('m-anage.userDataUpdated', catchUpdate)
    return () => {
      window.removeEventListener('m-anage.userDataUpdated', catchUpdate)
    }
  }, [dispatch])

  useEffect(() => {
    fetchComponentTemplatedResources({
      manageSubUrl: 'vmx.my-profile/vmx.component.my-profile',
      componentType: 'vue',
      version: '1.4.1',
    })
  }, [])

  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)
  const {
    oauthUrl,
    apiUrl,
    short,
    instance,
    language,
    identityUrl,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)

  const userEmail = useShallowEqualSelector((state) => {
    const { User } = state.appInfoReducer.summery
    if (User) {
      return decodeURIComponent(User.email)
    }
    return ''
  })

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      <LinkContainer>
        <SubRoutesButtons subRoutes={subRoutes}>
          {
            ({ url, title: subRouteTitle }) => (
              <ButtonLink link={{ url }}>
                {t(`My_Profile_Page-${subRouteTitle}`)}
              </ButtonLink>
            )
          }
        </SubRoutesButtons>
        <ButtonLink
          isExternal
          link={{
            url: `${window.location.origin}/auth/changepassword?email=${userEmail}`,
          }}
        >
          {t('My_Profile_Page-Change_Password')}
        </ButtonLink>
        <ButtonLink
          isExternal
          link={{
            url: `${window.location.origin}/auth/changeemail?email=${userEmail}`,
          }}
        >
          {t('My_Profile_Page-Change_Email')}
        </ButtonLink>
      </LinkContainer>
      <div
        id="my-profile"
        token={token}
        identity_url={identityUrl}
        oauth_url={oauthUrl}
        client_id=""
        api_url={apiUrl}
        instance={instance}
        language={language}
        event_short={short}
        context="Profile"
        hide_title="true"
        color={theme.primaryColor}
      />
    </PageContainer>
  )
}

MyProfileRoot.propTypes = {
  title: PropTypes.string.isRequired,
  subRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      componentName: PropTypes.string.isRequired,
      moduleName: PropTypes.string,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      props: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
      Props: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
      subRoutes: PropTypes.arrayOf(
        PropTypes.shape({
          componentName: PropTypes.string.isRequired,
          moduleName: PropTypes.string,
          name: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          props: PropTypes.shape({
            title: PropTypes.string.isRequired,
          }),
          Props: PropTypes.shape({
            title: PropTypes.string.isRequired,
          }),
        }).isRequired,
      ),
    }).isRequired,
  ),
}

MyProfileRoot.defaultProps = {
  subRoutes: [],
}
